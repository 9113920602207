import { Injectable } from '@angular/core';
import { UserInfo } from '../models/interface';
import {
  DesiredRoles,
  LAFV_ADMIN,
  LAFV_FMA,
  LAFV_FUND_ADMIN,
  LAFV_ORG_ADMIN,
  LAFV_PASSIVE_MEMBER,
  SUPER_USER,
} from '../shared/userRole-const';

@Injectable({
  providedIn: 'root',
})
export class LafvRoleMapService {
  mapRoles(userInfo: UserInfo): UserInfo {
    if (!userInfo || !userInfo.role) {
      return userInfo;
    }

    userInfo.role = userInfo.role
      .filter((role: string) => DesiredRoles.includes(role))
      .map((role: string) => this.mapRoleLabel(role));

    return userInfo;
  }

  private mapRoleLabel(role: string): string {
    switch (role) {
      case SUPER_USER:
        return 'SHARED.LABEL_ROLE_SUPER_USER';
      case LAFV_ADMIN:
        return 'SHARED.LABEL_ROLE_LAFV_ADMIN';
      case LAFV_ORG_ADMIN:
        return 'SHARED.LABEL_ROLE_LAFV_ORG_ADMIN';
      case LAFV_FUND_ADMIN:
        return 'SHARED.LABEL_ROLE_LAFV_FUND_ADMIN';
      case LAFV_FMA:
        return 'SHARED.LABEL_ROLE_LAFV_FMA';
      case LAFV_PASSIVE_MEMBER:
        return 'SHARED.LABEL_ROLE_LAFV_PASSIVE_MEMBER';
      default:
        return role;
    }
  }
}
