import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { OqtaneAuthService } from './oqtane-auth.service';

export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private oqtaneAuth: OqtaneAuthService,
    private router: Router,
    private lafvApiUrl: string,
    private oqtApiUrl: string
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isApiOriginUrl(request)) return next.handle(request);

    if (this.isApiTokenRefreshUrl(request)) {
      return next.handle(request).pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            console.error('401');
          }

          return throwError(() => response);
        })
      );
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          return this.oqtaneAuth.refreshAccessToken().pipe(
            switchMap((token) => {
              if (token) {
                request = request.clone({
                  setHeaders: {
                    Authorization: 'Bearer ' + token,
                  },
                });
              } else {
                // force page reload and redirect to login
                // token refresh fails, either cookie is invalid or user logged out
                window.location.reload();
              }

              return next.handle(request);
            })
          );
        }
        return throwError(() => response);
      })
    );
  }

  private isApiTokenRefreshUrl(req: HttpRequest<unknown>): boolean {
    return req.url.startsWith(`${this.oqtApiUrl}/api/LafvAuth/Token`);
  }

  private isApiOriginUrl(req: HttpRequest<unknown>): boolean {
    return (
      req.url.startsWith(`${this.lafvApiUrl}`) ||
      req.url.startsWith(`${this.oqtApiUrl}`)
    );
  }
}
