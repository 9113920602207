import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { InsightsService } from './insights.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
})
export class InsightsModule {
  static insights: InsightsService;

  constructor(private insights: InsightsService) {
    InsightsModule.insights = insights;
  }
}
