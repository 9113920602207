export * from './bankAccount.service';
import { BankAccountLafvSvc } from './bankAccount.service';
export * from './choice.service';
import { ChoiceLafvSvc } from './choice.service';
export * from './choiceSet.service';
import { ChoiceSetLafvSvc } from './choiceSet.service';
export * from './country.service';
import { CountryLafvSvc } from './country.service';
export * from './currency.service';
import { CurrencyLafvSvc } from './currency.service';
export * from './currencyRate.service';
import { CurrencyRateLafvSvc } from './currencyRate.service';
export * from './dataHistory.service';
import { DataHistoryLafvSvc } from './dataHistory.service';
export * from './dnnAutoLogin.service';
import { DnnAutoLoginLafvSvc } from './dnnAutoLogin.service';
export * from './export.service';
import { ExportLafvSvc } from './export.service';
export * from './fund.service';
import { FundLafvSvc } from './fund.service';
export * from './fundDist.service';
import { FundDistLafvSvc } from './fundDist.service';
export * from './fundDocument.service';
import { FundDocumentLafvSvc } from './fundDocument.service';
export * from './fundDocumentCategoryPivot.service';
import { FundDocumentCategoryPivotLafvSvc } from './fundDocumentCategoryPivot.service';
export * from './fundFee.service';
import { FundFeeLafvSvc } from './fundFee.service';
export * from './fundHeader.service';
import { FundHeaderLafvSvc } from './fundHeader.service';
export * from './fundInCountry.service';
import { FundInCountryLafvSvc } from './fundInCountry.service';
export * from './fundNav.service';
import { FundNavLafvSvc } from './fundNav.service';
export * from './fundNote.service';
import { FundNoteLafvSvc } from './fundNote.service';
export * from './fundUmbrella.service';
import { FundUmbrellaLafvSvc } from './fundUmbrella.service';
export * from './homeView.service';
import { HomeViewLafvSvc } from './homeView.service';
export * from './importLog.service';
import { ImportLogLafvSvc } from './importLog.service';
export * from './invoice.service';
import { InvoiceLafvSvc } from './invoice.service';
export * from './language.service';
import { LanguageLafvSvc } from './language.service';
export * from './membershipFee.service';
import { MembershipFeeLafvSvc } from './membershipFee.service';
export * from './organization.service';
import { OrganizationLafvSvc } from './organization.service';
export * from './paymentTerm.service';
import { PaymentTermLafvSvc } from './paymentTerm.service';
export * from './ping.service';
import { PingLafvSvc } from './ping.service';
export * from './scheduler.service';
import { SchedulerLafvSvc } from './scheduler.service';
export * from './statistic.service';
import { StatisticLafvSvc } from './statistic.service';
export const APIS = [BankAccountLafvSvc, ChoiceLafvSvc, ChoiceSetLafvSvc, CountryLafvSvc, CurrencyLafvSvc, CurrencyRateLafvSvc, DataHistoryLafvSvc, DnnAutoLoginLafvSvc, ExportLafvSvc, FundLafvSvc, FundDistLafvSvc, FundDocumentLafvSvc, FundDocumentCategoryPivotLafvSvc, FundFeeLafvSvc, FundHeaderLafvSvc, FundInCountryLafvSvc, FundNavLafvSvc, FundNoteLafvSvc, FundUmbrellaLafvSvc, HomeViewLafvSvc, ImportLogLafvSvc, InvoiceLafvSvc, LanguageLafvSvc, MembershipFeeLafvSvc, OrganizationLafvSvc, PaymentTermLafvSvc, PingLafvSvc, SchedulerLafvSvc, StatisticLafvSvc];
