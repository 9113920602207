import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ITraceTelemetry } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class InsightsService implements OnDestroy {
  private subs = new SubSink();

  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.appInsights.connectionString,
      loggingLevelConsole: environment.production ? undefined : 2,
      loggingLevelTelemetry: environment.production ? undefined : 2,
      extensions: [this.angularPlugin as any], // this is required and safe see https://github.com/microsoft/applicationinsights-angularplugin-js/issues/97
      extensionConfig: {
        [this.angularPlugin.identifier]: { router: this.router },
      },
      // Required for end-to-end traces
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      // Domains where we don't want to send tracing headers, since it could lead to errors
      correlationHeaderExcludedDomains: [],
    },
  });

  public get instance(): ApplicationInsights {
    return this.appInsights;
  }

  constructor(private readonly router: Router) {
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags || [];
      envelope.tags.push({
        'ai.cloud.role': environment.appInsights.applicationName,
      });
    });

    // subscribe to route changes to log how long a user spends on a page
    // its currently unclear if AngularPlugin already does this or not
    this.subs.sink = this.router.events
      .pipe(
        filter(
          (event: unknown): event is ResolveEnd => event instanceof ResolveEnd
        )
      )
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent)
          this.appInsights.trackPageView({
            name: activatedComponent.name,
            uri: event.urlAfterRedirects,
          });
      });
  }

  ngOnDestroy(): void {
    if (this.subs) this.subs.unsubscribe();
  }

  private getActivatedComponent(
    snapshot: ActivatedRouteSnapshot
  ): IPageViewTelemetry | null {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  /**
   * Set the authenticated user id and the account id. Used for identifying a specific signed-in user. Parameters must not contain whitespace or ,;=|
   *
   * The method will only set the `authenticatedUserId` and `accountId` in the current page view. To set them for the whole session, you should set `storeInCookie = true`
   * @param authenticatedUserId
   * @param [accountId]
   * @param [storeInCookie=false]
   */
  setAuthenticatedUserContext(
    authenticatedUserId: string,
    accountId?: string,
    storeInCookie?: boolean
  ): void {
    this.appInsights.setAuthenticatedUserContext(
      authenticatedUserId,
      accountId,
      storeInCookie
    );
  }

  trackEvent(
    event: IEventTelemetry,
    customProperties?: ICustomProperties
  ): void {
    this.appInsights.trackEvent(event, customProperties);
  }

  trackTrace(
    trace: ITraceTelemetry,
    customProperties?: ICustomProperties
  ): void {
    this.appInsights.trackTrace(trace, customProperties);
  }

  trackException(
    exception: IExceptionTelemetry,
    customProperties?: ICustomProperties
  ): void {
    this.appInsights.trackException(exception, customProperties);
  }

  trackDependencyData(dependency: IDependencyTelemetry): void {
    this.appInsights.trackDependencyData(dependency);
  }

  trackMetric(
    metric: IMetricTelemetry,
    customProperties?: ICustomProperties
  ): void {
    this.appInsights.trackMetric(metric, customProperties);
  }
}
