import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-currency-select',
  templateUrl: './formly-button.component.html',
  styleUrls: ['./formly-button.component.scss'],
})
export class FormlyButtonComponent extends FieldType {
  onClick($event: Event) {
    if (this.props['onClick']) {
      this.props['onClick']($event);
    }
  }
}
