<div *ngIf="userInfo$ | async as userInfo">
  <!-- User Info Table -->
  <h3>{{ 'HOME.LABEL.HELLO' | translate }} {{ userInfo.displayname }}</h3>

  <table class="table">
    <tbody>
      <tr>
        <th>{{ 'HOME.LABEL.YOU_ARE_ROLE' | translate }}</th>
        <td>
          <ng-container *ngFor="let role of userInfo?.role; let last = last">
            <span>{{ role | translate }}</span>
            <ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="userInfo.lafvOrganizationsNames?.length">
        <th>{{ 'HOME.LABEL.RESPONSIBLE_ORG' | translate }}</th>
        <td>
          <ng-container *ngFor="let organizationName of userInfo?.lafvOrganizationsNames; let last = last">
            {{ organizationName }}
            <br *ngIf="!last" />
          </ng-container>
        </td>
      </tr>

      <ng-container *ngIf="adminUserInfo$ | async; let adminUserInfo">
        <tr *ngIf="adminUserInfo && adminUserInfo.length > 0">
          <th>{{ 'HOME.LABEL.YOUR_USER_MANAGER' | translate }}</th>
          <td>
            <ng-container *ngFor="let adminInfo of adminUserInfo">
              <div class="admin-info-container d-flex">
                <a [href]="'mailto:' + adminInfo.email">{{ adminInfo.firstName }} &nbsp; {{ adminInfo.lastName }}</a>
              </div>
            </ng-container>
          </td>
        </tr>
      </ng-container>

    </tbody>
  </table>


  <div class="container-max-width">
    <ng-container *ngIf="!(hasOnlyRolePassiveMember$ | async)">
      <ng-container *ngIf="cardStatisticData$ | async as cardStatisticData">
        <!-- Fund Status Donat Chart -->
        <div class="row">
          <!-- Chart -->
          <div class="col-12 col-xl-9 mb-3">
            <google-chart *ngIf="chartData$ | async; let chartData" [title]="'HOME.TITLE.FUND_STATE' | translate"
              [type]="chartType" [data]="chartData" [options]="chartOptions" (select)="onChartSelect($event, chartData)"
              [height]="chartHeight" [width]="chartWidth">
            </google-chart>
          </div>
          <!-- Info Cards Right -->
          <div class="col-12 col-xl-3">
            <div class="row">
              <ng-container *ngFor="let content of cardStatisticData">
                <ng-container *ngFor="let cardData of content.right">
                  <!-- Cards Component -->
                  <app-card-info class="col-6 col-xl-12" [cardData]="cardData"></app-card-info>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- Info Cards Bottom -->
        <div class="row">
          <div class="col-12">
            <div class="row">
              <ng-container *ngFor="let content of cardStatisticData">
                <ng-container *ngFor="let cardData of content.bottom">
                  <!-- Cards Component -->
                  <app-card-info class="col-6 col-lg-3" [cardData]="cardData"></app-card-info>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="col-12">
      <iframe src="https://www.lafv.li/de/cockpit-infos" height="600" width="100%" title="2sic Info State"></iframe>
    </div>
  </div>
</div>
