import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DnnAutoLoginLafvSvc, MemberType } from 'lafv-api';
import { take } from 'rxjs';
import { NavigationItem } from './models/interface';
import { DataAndDocumentsIcons, ExportIcons, FundsIcons, InvoiceIcons, MemberAreaIcons, SystemIcons } from './shared/icons-const';
import {
  DocRouting,
  ExportRouting,
  FundDistributionRouting,
  FundHeaderRouting,
  FundNavRouting,
  FundsRouting,
  InvoiceRouting,
  SystemRouting,
  TerPtr,
  UmbrellaRouting,
  UploadHistory,
} from './shared/routing-const';

export const items: NavigationItem[] = [
  {
    pageTitle: _('SIDE_NAV_TOOLBAR.TITLE.FUNDS'),
    icon: FundsIcons.HeadLineSideNav,
    permission: 'viewFund',
    child: [
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.FUNDS'),
        icon: FundsIcons.FundsISIN,
        link: [FundsRouting.BaseName],
        permission: 'viewFundList',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.FUND_HEADER'),
        icon: FundsIcons.FundsSingeSub,
        link: [FundHeaderRouting.BaseName],
        permission: 'viewFundHeader',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.UMBRELLA'),
        icon: FundsIcons.Umbrella,
        link: [UmbrellaRouting.BaseName],
        permission: 'viewUmbrella',
      },
    ],
  },
  {
    pageTitle: _('SIDE_NAV_TOOLBAR.TITLE.DATA_DOCUMENTS'),
    icon: DataAndDocumentsIcons.HeadLineSideNav,
    permission: 'viewDataDocuments',
    child: [
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.NAV'),
        icon: DataAndDocumentsIcons.Nav,
        link: [FundNavRouting.BaseName],
        permission: 'viewFundNav',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.TER_PTR_LIST'),
        icon: DataAndDocumentsIcons.TerPtr,
        link: [TerPtr.BaseName],
        permission: 'viewTerPtrImport',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.DISTRIBUTION'),
        icon: DataAndDocumentsIcons.Distribution,
        link: [FundDistributionRouting.BaseName],
        permission: 'viewFundDistribution',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.DOCUMENTS'),
        icon: DataAndDocumentsIcons.DocumentsView,
        link: [DocRouting.DocumentView],
        permission: 'viewDocument',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.UPLOAD_HISTORY'),
        icon: DataAndDocumentsIcons.UploadHistory,
        link: [UploadHistory.BaseName],
        permission: 'viewUploadHistory',
      },
    ],
  },
  {
    pageTitle: _('SIDE_NAV_TOOLBAR.TITLE.EXPORT'),
    icon: ExportIcons.HeadLineSideNav,
    permission: 'viewExportPart',
    child: [
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.FMA'),
        icon: ExportIcons.FMA,
        link: [ExportRouting.BaseName, ExportRouting.Fma],
        permission: 'viewFmaExport',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.EFAMA'),
        icon: ExportIcons.EFAMA,
        link: [ExportRouting.BaseName, ExportRouting.Efama],
        permission: 'viewEfamaExport',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.FUNDS_EXPORT'),
        icon: ExportIcons.FundExport,
        link: [ExportRouting.BaseName, ExportRouting.Funds],
        permission: 'viewFundsExport',
      },
    ],
  },
  {
    pageTitle: _('SIDE_NAV_TOOLBAR.TITLE.MEMBER_AREA'),
    icon: MemberAreaIcons.HeadLineSideNav,
    permission: 'viewMemberArea',
    onClick: (dnnAutoLoginLafvSvc: DnnAutoLoginLafvSvc) => {
      dnnAutoLoginLafvSvc.getAutoLoginLink().pipe(take(1)).subscribe((res) => {
        window.open(res, '_blank');
      });
    }
  },
  {
    pageTitle: _('SIDE_NAV_TOOLBAR.TITLE.INVOICE'),
    icon: InvoiceIcons.HeadLineSideNav,
    permission: 'viewInvoicePart',
    child: [
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.INVOICE_CREATE'),
        icon: InvoiceIcons.Create,
        link: [InvoiceRouting.BaseName, InvoiceRouting.Create],
        permission: 'viewInvoiceCreate',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.INVOICE_LIST'),
        icon: InvoiceIcons.InvoiceList,
        link: [InvoiceRouting.BaseName, InvoiceRouting.List],
        permission: 'viewInvoiceList',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.INVOICE_CONTROLLING'),
        icon: InvoiceIcons.Controlling,
        link: [InvoiceRouting.BaseName, InvoiceRouting.Controlling],
        permission: 'viewInvoiceControlling',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.INVOICE_SETTINGS'),
        icon: InvoiceIcons.Settings,
        link: [InvoiceRouting.BaseName, InvoiceRouting.Settings],
        permission: 'viewInvoiceSettings',
      },
    ],
  },
  {
    pageTitle: _('SIDE_NAV_TOOLBAR.TITLE.SYSTEM'),
    icon: SystemIcons.HeadLineSideNav,
    permission: 'viewSystemPart',
    child: [
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.CURRENCY_RATE'),
        icon: SystemIcons.CurrencyRate,
        link: [SystemRouting.BaseName, SystemRouting.CurrencyRate],
        permission: 'viewCurrencyRate',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.ORGANIZATION'),
        icon: SystemIcons.Organization,
        link: [SystemRouting.BaseName, SystemRouting.Organization],
        permission: 'viewOrganization',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.DROPDOWN_VALUES'),
        icon: SystemIcons.DropdownValues,
        link: [SystemRouting.BaseName, SystemRouting.DropdownValues],
        permission: 'viewDropdDownValues',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.DATA_HISTORY'),
        icon: SystemIcons.DataHistory,
        link: [SystemRouting.BaseName, SystemRouting.DataHistory],
        permission: 'viewDataHistory',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.USER'),
        icon: SystemIcons.User,
        link: [SystemRouting.BaseName, SystemRouting.User],
        permission: 'viewUser',
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.MEMBER_AREA_PRIMARY_ACTIVE_MEMBER'),
        icon: MemberAreaIcons.HeadLineSideNav,
        permission: 'viewMemberAreaTesting',
        onClick: (dnnAutoLoginLafvSvc: DnnAutoLoginLafvSvc) => {
          dnnAutoLoginLafvSvc.getAdminAutoLoginLink(MemberType.PrimaryActiveMember).pipe(take(1)).subscribe((res) => {
            window.open(res, '_blank');
          });
        }
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.MEMBER_AREA_ACTIVE_MEMBER'),
        icon: MemberAreaIcons.HeadLineSideNav,
        permission: 'viewMemberAreaTesting',
        onClick: (dnnAutoLoginLafvSvc: DnnAutoLoginLafvSvc) => {
          dnnAutoLoginLafvSvc.getAdminAutoLoginLink(MemberType.ActiveMember).pipe(take(1)).subscribe((res) => {
            window.open(res, '_blank');
          });
        }
      },
      {
        pageTitle: _('SIDE_NAV_TOOLBAR.LABEL.MEMBER_AREA_PASSIVE_MEMBER'),
        icon: MemberAreaIcons.HeadLineSideNav,
        permission: 'viewMemberAreaTesting',
        onClick: (dnnAutoLoginLafvSvc: DnnAutoLoginLafvSvc) => {
          dnnAutoLoginLafvSvc.getAdminAutoLoginLink(MemberType.PassiveMember).pipe(take(1)).subscribe((res) => {
            window.open(res, '_blank');
          });
        }
      },
    ],
  },
];
