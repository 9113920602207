<input
  matInput
  [id]="id"
  [type]="'text'"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="frmControl"
  [formlyAttributes]="field"
  [mask]="mask"
  [dropSpecialCharacters]="dropSpecialCharacters"
  [leadZero]="leadZero"
  [allowNegativeNumbers]="allowNegativeNumbers"
  [readonly]="props.readonly"
/>
