import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, isObservable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class FormlyTranslateExtension {
  constructor(private translate: TranslateService) {}
  prePopulate(field: FormlyFieldConfig): void {
    const props = field.props || {};
    if (!props['translate'] || props['_translated']) return;

    props['_translated'] = true;

    if (props.label != null) {
      const label$ = this.translate.stream(props.label);

      let translate$: Observable<string>;
      if (translate$! == null) translate$ = label$;

      field.expressions = {
        ...(field.expressions || {}),
        'props.label': translate$,
      };
    }

    if (props.placeholder != null) {
      const placeholder$ = this.translate.stream(props.placeholder);

      let translate$: Observable<string>;
      if (translate$! == null) translate$ = placeholder$;

      field.expressions = {
        ...(field.expressions || {}),
        'props.placeholder': translate$,
      };
    }

    if (props.description != null) {
      const description$ = this.translate.stream(props.description);

      let translate$: Observable<string>;
      if (translate$! == null) translate$ = description$;

      field.expressions = {
        ...(field.expressions || {}),
        'props.description': translate$,
      };
    }

    if (props['selectAllOption'] != null) {
      field.expressions = {
        ...(field.expressions || {}),
        'props.selectAllOption': this.translate.stream(
          props['selectAllOption']
        ),
      };
    }

    if (Array.isArray(props.options)) {
      const options = props.options;
      props.options = this.translate.stream(options.map((o) => o.label)).pipe(
        map((labels) => {
          return options.map((o) => ({
            ...o,
            label: labels[o.label],
          }));
        })
      );
    } else if (isObservable(props.options)) {
      props.options = props.options.pipe(
        switchMap((options) => {
          if (!Array.isArray(options)) return options;
          return this.translate.stream(options.map((o) => o.label)).pipe(
            map((labels) => {
              return options.map((o) => ({
                ...o,
                label: labels[o.label],
              }));
            })
          );
        })
      );
    }
  }
}

export function registerFormlyTranslateExtension(
  translate: TranslateService
): any {
  return {
    validationMessages: [
      {
        name: 'required',
        message(): any {
          return translate.stream('SHARED.LABEL.VALIDATION_REQUIRED');
        },
      },
    ],
    extensions: [
      {
        name: 'translate',
        extension: new FormlyTranslateExtension(translate),
      },
    ],
  };
}
