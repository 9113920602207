<xng-breadcrumb [separator]="iconTemplate">
  <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first">
    <mat-icon *ngIf="info">{{sharedIcons.Home}}</mat-icon>
    <ng-container *ngIf="!first">{{ breadcrumb | translate }}</ng-container>
  </ng-container>
</xng-breadcrumb>

<ng-template #iconTemplate>
  <mat-icon>{{sharedIcons.ArrowRight}}</mat-icon>
</ng-template>
