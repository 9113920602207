import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration as LafvConfiguration } from 'lafv-api';
import { Configuration as OqtConfiguration } from 'oqtane-api';
import { AuthInterceptor } from './auth.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { OqtaneAuthService } from './oqtane-auth.service';
import { OqtaneLoginInterceptor } from './oqtane-login.interceptor';
import { TranslationAssetsInterceptor } from './translation-assets-interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [OqtaneAuthService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslationAssetsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: oqtaneLoginInterceptorFactory,
      deps: [OqtConfiguration],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: authorizeInterceptorFactory,
      deps: [OqtaneAuthService, Router, LafvConfiguration, OqtConfiguration],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule { }

export function oqtaneLoginInterceptorFactory(
  oqtApiConfiguration: OqtConfiguration
): any {
  return new OqtaneLoginInterceptor(oqtApiConfiguration.basePath ?? '');
}

export function authorizeInterceptorFactory(
  oqtaneAuth: OqtaneAuthService,
  router: Router,
  lafvApiConfiguration: LafvConfiguration,
  oqtApiConfiguration: OqtConfiguration
): any {
  return new AuthInterceptor(
    oqtaneAuth,
    router,
    lafvApiConfiguration.basePath ?? '',
    oqtApiConfiguration.basePath ?? ''
  );
}

function initializeAppFactory(oqtaneAuth: OqtaneAuthService): any {
  return () => oqtaneAuth.refreshAccessToken();
}
