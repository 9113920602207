import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export class OqtaneLoginInterceptor implements HttpInterceptor {
  constructor(private oqtApiUrl: string) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.isApiLoginLogoutUrl(request)) return next.handle(request);

    /*
    oqtane ignores forgery token if Authorization header is present
    this is minor security issue in oqtane, but it is not a problem for us

    if shawn fixes this in future we need to implement another workaround
    atm AutoValidateAntiforgeryTokenFilter is applied as a global filter with no way to opt out
    */
    request = request.clone({
      setHeaders: {
        Authorization: `dummy-string`,
      },
    });

    return next.handle(request);
  }

  private isApiLoginLogoutUrl(req: HttpRequest<unknown>): boolean {
    return (
      req.url.startsWith(`${this.oqtApiUrl}/api/User/login`) ||
      req.url.startsWith(`${this.oqtApiUrl}/api/User/logout`)
    );
  }
}
