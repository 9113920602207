import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import {
  AccountRouting,
  ExportRouting,
  FundDistributionRouting,
  FundHeaderRouting,
  FundNavRouting,
  FundsRouting,
  InvoiceRouting,
  RootRouting,
  SystemRouting,
  UmbrellaRouting,
} from './shared/routing-const';

const routes: Routes = [
  {
    path: '',
    redirectTo: RootRouting.Home,
    pathMatch: 'full',
    data: {
      breadcrumb: {
        info: 'home',
      },
    },
  },

  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: AccountRouting.BaseName,
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    data: { breadcrumb: 'Account' },
  },
  {
    path: FundsRouting.BaseName,
    loadChildren: () =>
      import('./fund/funds.module').then((m) => m.FundsModule),
    data: { breadcrumb: 'Fund' },
    canActivate: [AuthGuard],
  },
  {
    path: FundHeaderRouting.BaseName,
    loadChildren: () =>
      import('./fundHeader/fundHeader.module').then((m) => m.FundHeaderModule),
    data: { breadcrumb: 'FundOrg' },
    canActivate: [AuthGuard],
  },
  {
    path: UmbrellaRouting.BaseName,
    loadChildren: () =>
      import('./fundUmbrella/fundUmbrella.module').then(
        (m) => m.FundUmbrellaModule
      ),
    data: { breadcrumb: 'Umbrella' },
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./doc/doc.module').then((m) => m.DocModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./fundTerPtr/fundTerPtr.module').then((m) => m.FundTerPtrModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./uploadHistory/uploadHistory.module').then(
        (m) => m.UploadHistoryModule
      ),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard],
  },
  {
    path: FundDistributionRouting.BaseName,
    loadChildren: () =>
      import('./fundDistribution/fundDistribution.module').then(
        (m) => m.FundDistributionModule
      ),
    data: { breadcrumb: 'Fund Distribution' },
    canActivate: [AuthGuard],
  },
  {
    path: FundNavRouting.BaseName,
    loadChildren: () =>
      import('./fundNav/fundNav.module').then((m) => m.FundNavModule),
    data: { breadcrumb: 'Fund Nav' },
    canActivate: [AuthGuard],
  },
  {
    path: SystemRouting.BaseName,
    loadChildren: () =>
      import('./system/system.module').then((m) => m.SystemModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard],
  },
  {
    path: ExportRouting.BaseName,
    loadChildren: () =>
      import('./export/export.module').then((m) => m.ExportModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard],
  },

  {
    path: InvoiceRouting.BaseName,
    loadChildren: () =>
      import('./invoice/invoice.module').then((m) => m.InvoiceModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [AuthGuard],
  },

  { path: '404', component: PageNotFoundComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
