export const AccountRouting = {
  BaseName: 'account',
  Login: 'login',
  Logout: 'logout',
  Register: 'register',
  ForgotPassword: 'forgot-password',
  ChangePassword: 'change-password',
};

export const FundsRouting = {
  BaseName: 'fund',
  FundDetailId: 'fundDetailId',
  NavId: 'navId',
  Edit: 'edit',
  Add: 'add',
  Nav: 'nav',
  FeeBaseName: 'fee',
  FeeId: 'feeId',
  StatusTimeLine: 'status-timeline',
};

export const FundHeaderRouting = {
  BaseName: 'fundorg',
  FundHeaderId: 'fundHeaderId',
  DocumentView: 'document-view',
  Add: 'add',
  Edit: 'edit',
};

export const UmbrellaRouting = {
  BaseName: 'umbrella',
  UmbrellaId: 'umbrellaId',
  Edit: 'edit',
  Add: 'add',
};

export const DocRouting = {
  DocumentUpload: 'document-upload',
  DocumentView: 'document-view',
  TerPtrImport: 'ter-ptr',
};

export const UploadHistory = {
  BaseName: 'upload-history',
  Id: 'id',
};

export const TerPtr = {
  BaseName: 'TerPtr',
};

export const FundDistributionRouting = {
  BaseName: 'fundDistribution',
  DistributionFundId: 'fundDetailId',
  DistributionId: 'distributionId',
  Edit: 'edit',
  Add: 'add',
};

export const FundNavRouting = {
  BaseName: 'fundNavs',
  NavFundId: 'fundDetailId',
  NavId: 'navId',
  Edit: 'edit',
  Add: 'add',
};

export const InvoiceRouting = {
  BaseName: 'invoice',
  List: 'list',
  Create: 'create',
  Settings: 'settings',
  MemberShip: 'membershipFee',
  MemberShipFeeDetailId: 'membershipFeeId',
  Controlling: 'controlling',
};

export const SystemRouting = {
  BaseName: 'system',
  CurrencyRate: 'currency-rate',
  CurrencyRateId: 'currencyRateId',
  DataHistory: 'data-history',
  DataHistroyId: 'dataHistroyId',
  Organization: 'organization',
  OrganizationId: 'organizationId',
  DropdownValues: 'dropdownvalues',
  DropdownValuesSetName: 'setName',
  DropdownValuesKey: 'key',
  User: 'user',
  UserId: 'userId',
  Edit: 'edit',
  Add: 'add',
};

export const ExportRouting = {
  BaseName: 'export',
  Fma: 'fma',
  Efama: 'efama',
  Funds: 'funds',
};

export const RootRouting = {
  Home: 'home',
};
