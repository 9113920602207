import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { ChartType } from 'angular-google-charts';
import {
  HomeViewLafvSvc,
  OrganizationLafvSvc
} from 'lafv-api';
import { LafvUserOqtSvc, OqtaneModelsUserDto, UserOqtSvc } from 'oqtane-api';
import {
  Observable,
  combineLatest,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { SubSink } from 'subsink';
import { OqtaneAuthService } from '../core/oqtane-auth.service';
import { LogEvent } from '../insights/insights.decorator';
import { InsightsService } from '../insights/insights.service';
import { UserInfo } from '../models/interface';
import { LafvRoleMapService } from '../service/lafv-role-map.service';
import { DataAndDocumentsIcons, FundsIcons, UserIcons } from '../shared/icons-const';
import {
  DocRouting,
  FundHeaderRouting,
  FundNavRouting,
  FundsRouting,
  SystemRouting,
  UmbrellaRouting,
} from '../shared/routing-const';
import { LafvUser } from '../system/user/user-list/user-list.component';
import { AdminUserInfo, CardInfo } from './home.models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  fundsRouting = FundsRouting;


  chartHeight = 350;
  chartWidth!: number;

  userInfo$: Observable<UserInfo> = this.oqtaneAuthService.getUserInfo().pipe(
    map((userInfo) => this.lafvRoleMapService.mapRoles(userInfo as UserInfo)),
    switchMap((data) => {
      const organizationsIds = data.LafvOrganizations;
      const organizationsAdminIds = data.LafvOrganizationsAdmin;
      const organizationsIdsArray = Array.isArray(organizationsIds)
        ? organizationsIds
        : organizationsIds
          ? [organizationsIds]
          : [];

      const organizationsAdminIdsArray = Array.isArray(organizationsAdminIds)
        ? organizationsAdminIds
        : organizationsAdminIds
          ? [organizationsAdminIds]
          : [];

      const orgViewNames$ = organizationsIdsArray.length
        ? this.organizationLafvSvc.getOrganizationByIds(organizationsIdsArray)
        : of([]);

      const orgAdminNames$ = organizationsAdminIdsArray.length
        ? this.organizationLafvSvc.getOrganizationByIds(
          organizationsAdminIdsArray
        )
        : of([]);

      return forkJoin([orgViewNames$, orgAdminNames$]).pipe(
        map(([orgViewNames, orgAdminNames]) => {
          const userInfoWithOrgNames: UserInfo = {
            ...data,
            lafvOrganizationsNames: orgViewNames,
            lafvOrganizationsAdminNames: orgAdminNames,
          };
          return userInfoWithOrgNames;
        })
      );
    })
  );

  hasOnlyRolePassiveMember$: Observable<boolean> = this.userInfo$.pipe(
    map((userInfo) => userInfo.role.includes('SHARED.LABEL_ROLE_LAFV_PASSIVE_MEMBER') && userInfo.role.length === 1),
    startWith(true)
  );

  adminUserInfo$ = this.lafvUserOqtSvc
    .apiLafvUserAdminUsersGet().pipe(
      map((data) =>
        data.flatMap((item) => {
          const user = item.user as LafvUser;

          const firstNameSetting = item.settings?.find(
            (f) => f.settingName === 'FirstName'
          );
          const lastNameSetting = item.settings?.find(
            (f) => f.settingName === 'LastName'
          );

          const adminUserInfo: AdminUserInfo = {
            displayName: user.displayName || undefined,
            firstName: firstNameSetting?.settingValue || undefined,
            lastName: lastNameSetting?.settingValue || undefined,
            email: user.email || undefined,
          };
          return adminUserInfo;
        })
      ),
      map((result) => {
        return result.filter((adminUserInfo) => adminUserInfo !== null) as AdminUserInfo[];
      }));


  title = 'Fund State';

  chartType = ChartType.PieChart;
  chartOptions = {
    pieHole: 0.4,
    // width: 650,
    // height: 350,
  };
  DocRouting: any;

  get chartData$(): Observable<any[]> {
    return this._chartData$;
  }

  private _chartData$: Observable<any[]> = this.homeViewLafvSvc
    .getFundStatusCount()
    .pipe(
      switchMap((response) =>
        combineLatest([
          this.translate.onLangChange.pipe(
            startWith({ lang: this.translate.currentLang })
          ),
          of(response),
        ])
      ),
      map(([event, response]) => {
        const chartData = response.map((item) => {
          const statusName =
            event.lang === 'de' ? item.statusNameDe : item.statusNameEn;
          return [statusName, item.count, item.status];
        });
        chartData.sort((a: any, b: any) => b[1] - a[1]);
        return chartData;
      }),
      tap((data) => {
        this.insights.trackTrace({
          message: `${this.constructor.name}.chartData$`,
          severityLevel: SeverityLevel.Verbose,
          properties: { result: data },
        });
      })
    );

  get cardStatisticData$(): Observable<CardInfo[]> {
    return this._cardStatisticData$;
  }

  private _cardStatisticData$: Observable<CardInfo[]> = forkJoin([
    this.homeViewLafvSvc.getStatusCounts(),
    this.lafvUserOqtSvc.apiLafvUserAdminCountsGet(),
  ]).pipe(
    map(([countsLafvBackend, adminCounts]) => {
      return [
        {
          right: [
            {
              // NAV
              viewInfo: [
                {
                  name: _('HOME.LABEL.NAV'),
                  icon: DataAndDocumentsIcons.Nav,
                  dateData: countsLafvBackend.latestNav,
                },
              ],
              route: FundNavRouting.BaseName,
              toolTipInfo: {
                label: _('HOME.TOOLTIP.NAV'),
                dateData: countsLafvBackend.latestNav,
              },
            },
            {
              // Documents
              viewInfo: [
                {
                  name: _('SIDE_NAV_TOOLBAR.LABEL.DOCUMENTS'),
                  icon: DataAndDocumentsIcons.DocumentsView,
                  count: countsLafvBackend.documentCount,
                },
              ],
              route: DocRouting.DocumentView,
              toolTipInfo: {
                label: _('HOME.TOOLTIP.DOCUMENTS'),
              },
            },
          ],

          bottom: [
            {
              // Umbrella
              viewInfo: [
                {
                  name: _('SHARED.LABEL.UMBRELLA'),
                  icon: FundsIcons.Umbrella,
                  count: countsLafvBackend.umbrellaCount,
                },
              ],
              route: UmbrellaRouting.BaseName,
              toolTipInfo: {
                label: _('SHARED.LABEL.UMBRELLA'),
              },
            },
            {
              // Fundheader
              viewInfo: [
                {
                  name: _('SHARED.LABEL.FUNDHEADER'),
                  icon: FundsIcons.FundsSingeSub,
                  count: countsLafvBackend.fundHeaderCount,
                },
              ],
              route: FundHeaderRouting.BaseName,
              toolTipInfo: {
                label: _('SHARED.LABEL.FUNDHEADER'),
              },
            },
            {
              // Fund
              viewInfo: [
                {
                  name: _('SHARED.LABEL.FUNDS'),
                  icon: FundsIcons.FundsISIN,
                  count: countsLafvBackend.fundCount,
                },
              ],
              route: this.fundsRouting.BaseName,
              toolTipInfo: {
                label: _('SHARED.LABEL.FUNDS'),
              },
            },
            {
              // Team / Org Admins
              viewInfo: [
                {
                  name: _('HOME.LABEL.TEAM'),
                  icon: UserIcons.User,
                  count: adminCounts.teamAdminCount,
                },
                {
                  name: _('HOME.LABEL.ORG_ADMINS'),
                  icon: UserIcons.ManageUser,
                  count: adminCounts.orgAdminCount,
                },
              ],
              route: SystemRouting.BaseName + '/' + SystemRouting.User,
              toolTipInfo: {
                label: _('HOME.TOOLTIP.AMOUNT_TEAM_ORG_ADMINS'),
              },
            },
          ],
        },
      ];
    }),
    tap((data) => {
      // sample for a trace call in a rxjs pipe
      this.insights.trackTrace({
        message: `${this.constructor.name}.cardStatisticData$`,
        severityLevel: SeverityLevel.Verbose,
        properties: { result: data },
      });
    })
  );

  constructor(
    private insights: InsightsService,
    private router: Router,
    private homeViewLafvSvc: HomeViewLafvSvc,
    private oqtaneAuthService: OqtaneAuthService,
    private translate: TranslateService,
    private organizationLafvSvc: OrganizationLafvSvc,
    private userOqtSvc: UserOqtSvc,
    private lafvUserOqtSvc: LafvUserOqtSvc,
    private lafvRoleMapService: LafvRoleMapService
  ) { }

  ngOnInit(): void {
    this.resizeChart();
  }

  ngOnDestroy(): void {
    if (this.subs) this.subs.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeChart();
  }

  resizeChart() {
    console;
    const tempChartWidth =
      window.innerWidth -
      document.querySelector('mat-sidenav')!.clientWidth -
      350;

    if (tempChartWidth > 400) this.chartWidth = 650;
    else if (tempChartWidth < 400) this.chartWidth = 500;
    else this.chartWidth = tempChartWidth;
  }

  @LogEvent()
  onChartSelect(event: any, chartData: any): void {
    const rowIndex = event.selection[0].row
    const fundStatusClicked = chartData[rowIndex!][2]; // status Lang, Value, StatusId ([2])

    const navigationExtras: NavigationExtras = {
      state: {
        fundStatus: fundStatusClicked
      }
    };

    this.router.navigate([this.fundsRouting.BaseName], navigationExtras);
  }

  onLoginClick(): void {
    this.userOqtSvc
      .apiUserLoginPost(true, true, {
        siteId: 1,
        username: 'host',
        password: '',
      } as OqtaneModelsUserDto)
      .subscribe();
  }

  generateTranslateString() {
    _('HOME.LABEL.COCKPIT');
    _('HOME.TITLE.FUND_STATE');
    _('HOME.TITLE.HOME');
    _('SHARED.LABEL_ROLE_SUPER_USER');
    _('SHARED.LABEL_ROLE_LAFV_ADMIN');
    _('SHARED.LABEL_ROLE_LAFV_ORG_ADMIN');
    _('SHARED.LABEL_ROLE_LAFV_FUND_ADMIN');
    _('SHARED.LABEL_ROLE_LAFV_FMA');
    _('SHARED.LABEL_ROLE_LAFV_PASSIVE_MEMBER');
  }

}
