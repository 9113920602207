import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService implements OnDestroy {
  private previousUrl!: string;
  private currentUrl: string;
  private sub = new SubSink();

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    this.sub.sink = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
