/**
 * Admin-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EfamaVersion = 'V1_05' | 'V1_06';

export const EfamaVersion = {
    _05: 'V1_05' as EfamaVersion,
    _06: 'V1_06' as EfamaVersion
};

