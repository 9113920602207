<h1 mat-dialog-title>{{ 'ACCOUNT.CHANGE_PASSWORD.TITLE' | translate }}</h1>
<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <formly-form [model]="model" [form]="form" [fields]="fields" [options]="options"></formly-form>
  </form>

  <mat-dialog-actions align="end">
    <button (click)="dialogRef.close()" mat-raised-button>{{ 'SHARED.LABEL.CANCEL' | translate }}</button>
    <button mat-raised-button color="primary" [class.disabled]="!form.valid || (saving$ | async)" (click)="submit()" type="submit">
      {{ 'SHARED.LABEL.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
